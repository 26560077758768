<template>
  <div>

    <div class=" pt-10 pl-10 pr-10">
      <div class="breadcrumbbar text-center">
        <Error class="pb-2" />
        <h1 id="noinfo">
          Trazabilidad de envio de productos
        </h1>

        <div class="col-12 pt-10">
            <validation-provider  name="folio">
              <v-text-field v-model="folio" v-on:keyup.enter="validateFolio()"
                suffix="Enter" class="br" label="ID ENVIO"
                placeholder="Identificador de envio" outlined >
              </v-text-field>
            </validation-provider>
          </div>
      </div>
    </div>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="blue-fmedical">
          <v-toolbar-title class="pt-4">
            <p class="text-center">
              <i class="feather icon-paperclip mr-1"></i>
              {{ currentItem.branchOfficeOriginName }}
            </p>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="cleanModal()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="container">
          <div class="row">
            <AlertMessageComponent ref="alertModal" />
            <div class="col-md-12 pb-0">
              <div class="card border-light">
                <div class="card-header pb-2 card-header-detail">
                  <h6>
                    <i class="fa fa-file-text-o mr-2 text-fmedical-blue"></i>
                    <strong>
                      <span>
                        Información de la orden de envio
                      </span>
                    </strong>
                  </h6>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-xs-12 col-md-6">
                      <p>
                        <i
                          class="feather icon-truck mr-1 text-fmedical-blue"
                        ></i>
                        <strong>
                          <span> {{ $t("picking.shipping_id") }}</span>
                        </strong>
                        {{ currentItem.idShipment }}
                      </p>
                    </div>
                    <div class="col-xs-12 col-md-6">
                      <p>
                        <i
                          class="feather icon-calendar mr-1 text-fmedical-blue"
                        ></i>
                        <strong>
                          <span> Fecha de envio:</span>
                        </strong>
                        {{ currentItem.dateShipment }}
                      </p>
                    </div>
                    <div class="col-xs-12 col-md-6">
                      <p>
                        <i
                          class="fa fa-flag-o mr-1 text-fmedical-blue"
                        ></i>
                        <strong>
                          <span> Sucursal origen </span>
                        </strong>
                        {{ currentItem.branchOfficeOriginName }}
                      </p>
                    </div>
                    <div class="col-xs-12 col-md-6">
                      <p>
                        <i
                          class="fa fa-hospital-o mr-1 text-fmedical-blue"
                        ></i>
                        <strong>
                          <span> Sucursal destino </span>
                        </strong>
                        {{ currentItem.branchOfficeDestinationName  }}
                      </p>
                    </div>



                    <div class="col-xs-12 col-md-6">
                      <p>
                        <i class="fa fa-check-circle-o mr-1 text-fmedical-blue"></i>
                        <strong>
                          <span> Estado del envio:</span>
                        </strong>

                        <ChipTableCommonPagination :status="currentItem.statusShipment" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <v-data-table
                item-key="idOrderStock"
                show-expand
                :headers="orderProductsHeaders"
                :items="dataList"
                height="auto"
                :search="search"
                :footer-props="{
                  'items-per-page-text': $t('table.item_table'),
                  'items-per-page-all-text': $t('table.all'),
                  'items-per-page-options': [10, 25, 50, -1],
                }"
                :loading-text="$t('table.loading')"
                :no-data-text="$t('table.no_results_available')"
                :no-results-text="$t('table.no_results_search')"
                dense
                
              >
                <template v-slot:top>
                  <div class="row justify-end">
                    <div
                      class="col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 pr-6 text-right"
                    >
                      <v-text-field
                        v-model="search"
                        :label="$t('table.search')"
                      ></v-text-field>
                    </div>
                  </div>
                </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <table>
                        <tr>
                          <td><b>ID ENTRADA: </b> {{ item.remissionId }} </td>
                          <td style="padding-left: 50px" ><b>PEDIDO / LICITACION: </b> {{ item.requisition }}</td>
                          <td></td>
                        </tr>
                        <tr> 
                          <td><b>REFERENCIA ENTRADA: </b> {{ item.remissionKey }} </td>
                          <td style="padding-left: 50px" ><b>FECHA DE CREACIÓN: </b> {{ item.createDate }} </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td><b>FACTURA: </b> {{ item.invoice }} </td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td><b>PROVEEDOR: </b> {{ item.supplier }} </td>
                          <td></td>
                          <td></td>
                        </tr>

                      </table>

                      
                    </td>
                  </template>

              </v-data-table>
            </div>
          </div>
        </div>
        <div class="row mt-8"></div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { UtilFront } from "@/core";
import { OrderStockRequest, ShipmentsRequest, } from "@/core/request";
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import ButtonTableComponent from "@/common/table/ButtonTableComponent";
import { PICKING_ORDER_TRANSPARENCY } from "@/core/DataTableHeadersLocale";
import ChipTableCommonPagination from "@/common/table/ChipTableCommonPagination";
import Error from "@/other/common/Error";

export default {
  data() {
    return {
      expanded: [],
      folio: "",
      orderProductsHeaders: PICKING_ORDER_TRANSPARENCY(),
      dataList: [],
      item: {},
      dialog: false,
      loading: null,
      search: "",
      currentItem: {
        branchOfficeDestination: {},
        shipment: {},
        isView: false,
      },
      parametro1: null,
      pagesAtributes: {
        currentPage: 0,
        itemsPerPage: 15,
        search: "",
        sortBy: "",
        sortDesc: true,
        sortFilters: {},
      },
    };
  },
  components: {
    ButtonTableComponent,
    AlertMessageComponent,
    ChipTableCommonPagination,
    Error
  },
  created(){

    this.parametro1 = this.$route.query.envio; 

    console.log(  this.parametro1 );

    if ( this.parametro1 != '' ) {
      if ( this.parametro1 != undefined  )
        this.reloadComponent( this.parametro1 );
    } 



  },
  mounted() {},
  methods: {
    validateFolio(){
      console.log(this.folio);
      this.reloadComponent( this.folio );
    },
    cleanModal() {
      this.dataList = [];
      this.dialog = false;
    },
    async reloadComponent( item ) {
      this.currentItem = {};

      //Mustro la pantalla de cargando
      this.loading = UtilFront.getLoadding();
      //Abro el modal
      this.dialog = true;

      this.pagesAtributes.search = item;
      //this.currentItem = item;
      await ShipmentsRequest.listAllShipmentsWithTypeAndStatus(this.pagesAtributes)
        .then((response) => {
          let data = response.data;
          

          this.currentItem = data.content.find(shipment => shipment.idShipment === item);
          if ( this.currentItem == undefined )
          this.currentItem = {};
          
          console.log( this.currentItem  );
        })
        .catch((error) => {
          this.dialog = false;
        })
        .finally(() => {
          this.loading.hide();
      });
    

      if ( this.currentItem != null )
        await OrderStockRequest.ordersStockDetailWithRemission({
          idOrder: this.currentItem.orderId,
          status: [1, 3, 4, 6],
        })
          .then((response) => {
            let { status, data } = response;

            if (status == 200) {
              this.dataList = data;
            } else if (status == 202) {
              this.$refs.alertModal.reloadComponent("error", data);
            }
          })
          .catch((error) => {
            this.dialog = false;
          })
          .finally(() => {
            //this.orderProductsHeaders = PICKING_ORDER();
            //Elimino la pantalla de cargando
            this.loading.hide();
          });
    },

  },
};
</script>
